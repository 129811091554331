@import './typography';
@import './colors';
@import './buttons';
@import './inputs';
@import './forms';
@import './layouts';
@import './slider';
@import './modals';

* {
    box-sizing: border-box;
    color: $black;
}
*:before,
*:after {
    box-sizing: border-box;
}
svg {
    box-sizing: content-box;
}
html,
body {
    height: 100%;
    position: relative;
}
a {
    font-size: 14px;
    line-height: 20px;
    color:var(--branding-color-primary);
    text-decoration: none;
    &.danger {
        color: $error;
    }
}
.cancel {
    @include btn-text-lg;
    margin-top: 42px;
}
.divider {
    background: $divider;
    height: 1px;
    width: 100%;
}
// loader
.loader {
    border: 2px solid #f3f3f3;
    border-top: 2px solid #6C7880;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.alert {
    margin-bottom: 16px;
}

.img-container {
    display: inline-block;
    position: relative;

    .overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, .6);
    }
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
    width: 100%;
    max-width: 500px;

    &.danger {
        color: #721c24;
        border-color: #721c24;
        background-color: #f8d7da;
    }

    &.success {
        color: #155724;
        border-color: #155724;
        background-color: #d4edda;
    }
}