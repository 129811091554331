
.scene-layout {
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @include text-md;
    > h2 {
        align-self: flex-start;
    }
    > *:last-child {
        margin-bottom: 40px;
    }
}