@import '~styles/typography';

#payment-scene {
    #nav-header {
        margin-bottom: 32px;
    }
    h2 {
        margin-bottom: 24px;
    }
    .save-payment-wrapper {
        display: flex;
        @include text-small;
        margin-bottom: 32px;
        margin-top: 24px;
        .checkbox {
            margin-right: 16px;
        }
    }
}