.confirm-modal-content {
    position: relative;
    height: 100%;
    text-align: center;
    line-height: 24px;
    h3 {
        margin-bottom: 16px;
        align-self: center;
        text-align: center;
    }
    .btn-group {
        display: flex;
        margin: 32px 0;
        button:first-child {
            margin-right: 16px;
            width: 96px;
        }
    }
}