@import '~styles/typography';
@import '~styles/colors';

#charge-started-scene {
    #nav-header {
        margin-bottom: 32px;
    }
    p {
        @include text-lg;
        margin: 0;
    }
    .completion-time {
        text-align: center;
        width: 100%;
        max-width: 500px;
        padding: 24px;
        border-radius: 10px;
        border: 1px solid $divider;
        background: linear-gradient(180deg, rgba(245, 246, 247, 0) 0%, #F0F2F2 100%);
        margin-bottom: 24px;
    }
    .details {
        @include text-small;
        color: $med-gray;
        margin-bottom: 24px;
    }
    .img-container {
            .icon-wrapper {
                border-radius: 64px;
                animation: shadow-pulse 1s infinite;
                width: 64px;
                height: 64px;
            }
            svg {
                border-radius: 100px;
                animation: shadow-pulse-small 1s infinite ;
                animation-delay: 0.1s;
            }

            @keyframes shadow-pulse-small
            {
                 0% {
                      box-shadow: 0 0 0 0px rgba(0, 66, 26, 0.4);
                 }
                 100% {
                      box-shadow: 0 0 0 25px rgba(0, 66, 26, 0);
                 }
            }

            @keyframes shadow-pulse
            {
                 0% {
                      box-shadow: 0 0 0 0px rgba(0, 66, 26, 0.3);
                 }
                 100% {
                      box-shadow: 0 0 0 50px rgba(0, 66, 26, 0);
                 }
            }
    }
}