@import '~rc-slider/assets/index.css';

.rc-slider {
    margin-top: 13px;
    max-width: 330px;
    .rc-slider-rail {
        height: 4px;
    }
    .rc-slider-track {
        height: 4px;
        background-color: $black;
    }
    .rc-slider-dot {
        display: none;
    }
    .rc-slider-handle {
        width: 40px;
        height: 40px;
        top: -8px;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), var(--branding-color-primary);
        border: 3px solid #FFFFFF;
        box-sizing: border-box;
        box-shadow: 0px 4px 8px rgba(9, 54, 86, 0.24);
        border-radius: 48px;
    }
    .rc-slider-mark {
        z-index: -1;
        position: relative;
        top: -30px;
        left: -10px;
        font-size: 24px;
        font-weight: bolder;
    }
}