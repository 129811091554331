@import './colors';

@mixin btn-number {
    @include text-lg;
    @include bold;
    font-family: 'Roboto Condensed';
    letter-spacing: 1px;
    min-width: initial;
    background-color: $white;
    border: 1px solid $divider;
    padding: 12px 20px;
    border-radius: 10px;
    width: 100%;
    text-align: center;

    &.selected {
        background: rgba(52, 152, 219, 0.1);
        border: 1.5px solidvar(--branding-color-primary);
    }
}

button.btn, a.btn {
    font-family: 'Roboto Condensed';
    border: none;
    letter-spacing: 2px;
    outline: none;
    cursor: pointer;
    text-transform: uppercase;
    width: 100%;
    max-width: 500px;
    font-size: 16px;
    line-height: 20px;
    text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 18px 16px;
    font-weight: bold;
    background-blend-mode: overlay, normal;
    &.no-highlight {
        -webkit-tap-highlight-color: transparent;
    }
    &:disabled {
        &:not(.btn-link) {
            background: #CED2D5 !important;
        }
        color: #9DA5AB !important;
    }
    &.btn-icon {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    &.btn-mega {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), var(--branding-color-primary);
        box-shadow: 0px 6px 12px rgba(9, 54, 86, 0.16);
        border-radius: 10px;
        padding: 36px;
        border-color: transparent;
        color: $white;
        font-size: 21px;
        line-height: 24px;
        &.btn-icon {
            padding: 16px 32px;
        }
    }
    &.btn-primary {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), $teq-blue;
        color: $white;
    }
    &.btn-secondary {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%), #0A1E2C;
        color: $white;
    }
    &.btn-link {
        background-color: transparent;
        padding: 0;

        &.link-danger {
            color: $error;
        }
    }
    &.btn-danger {
        background: linear-gradient(180deg, rgba(255, 255, 255, 0.3) 0%, rgba(255, 255, 255, 0) 100%), #DB3434;
        color: $white;
    }
    &.btn-number {
        @include btn-number;
    }
    &.btn-action {
        font-family: 'Roboto Condensed';
        display: flex;
        justify-content: center;
        align-items: center;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 2px;
        @include bold;
        border: 1px solid $divider;
        border-radius: 10px;
        background: $white;

        svg {
            margin-right: 16px;
        }
    }
}
