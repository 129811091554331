
@import '~styles/colors';
@import '~styles/typography';

#sign-up-scene {
    padding-top: 18px;
    h3 {
        margin-top: 52px;
        margin-bottom: 32px;
    }
}