@import '~styles/typography';
@import '~styles/colors';

#login-scene {
    padding-top: 40px;
    #logo {
        margin-bottom: 26px;
        width: 250px;
    }
    #tagline {
        margin-bottom: 46px;
    }
    form {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 500px;
        margin-bottom: 40px;
        > .icon-input-wrapper {
            margin-bottom: 8px;
            &:last-of-type {
                margin-bottom: 16px;
            }
        }
        > .btn.btn-link {
            font-family: Roboto;
            align-self: flex-end;
            margin-bottom: 20px;
            color:var(--branding-color-primary);
            @include text-small;
            text-align: right;
            letter-spacing: 0px;
            text-transform: none;
            font-weight: normal;
            display: inline-block;
            width: initial;
        }
    }
    .divider {
        margin-bottom: 40px;
    }
    .btn-secondary {
        margin-top: 16px;
        margin-bottom: 48px;
    }
}
