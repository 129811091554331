@import '~styles/typography';

.hours-modal-content {
    > button.btn.btn-number {
        margin-top: 16px;
        margin-bottom: 8px;
    }
    .number-buttons {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-row-gap: 5px;
        grid-column-gap: 5px;
        width: 100%;
        button.btn.btn-number {
            padding: 12px 13px;
            font-size: 16px;
            padding: 12px 13px;
        }
    }
}