@import '~styles/typography';
@import '~styles/colors';

#time-picker-scene {
    max-width: 100%;
    #nav-header {
        margin-bottom: 32px;
    }
    #sub-header {
        width: 100%;
        justify-content: space-between;
        position: relative;
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        a {
            position: absolute;
            justify-self: flex-start;
        }
        .details {
            text-align: center;
            width: 100%;
            .name {
                @include btn-text-lg;
            }
            .charger {
                @include text-tiny;
                @include bold-md;
                color: $med-gray;
            }
        }
    }
    h2 {
        text-align: center;
        margin-bottom: 32px;
        align-self: center;
    }
    .time {
        @include text-lg;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;
        button.btn.btn-number {
            width: 68px;
            height: 56px;
            margin-right: 12px;
            &:last-of-type {
                margin-left: 16px;
            }
        }
    }
    .rc-slider {
        margin-bottom: 40px;
    }
    .cost-wrapper, .payment-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: $med-gray;
        align-items: center;
    }
    .cost-wrapper {
        margin-bottom: 16px;
    }
    .payment-wrapper {
        margin-bottom: 32px;
        align-items: flex-start;
    }
    .cost, .payment {
        display: flex;
        flex-direction: column;
        @include text-lg;
        @include bold;
        color: $black;
    }
    .paymentMethod {
        @include text-md;
        display: flex;
        flex-direction: column;
        text-align: right;
        svg {
            margin-right: 10px;
            position: relative;
            top: 2px;
        }
        button.btn.btn-link {
            color:var(--branding-color-primary);
            @include text-tiny;
            font-weight: normal;
            text-transform: none;
            letter-spacing: 0px;
            font-family: Roboto;
        }
    }
}