@import '~styles/colors';
@import '~styles/typography';

.profile-form {
    width: 100%;
    display: flex;
    justify-content: center;
    .disclaimer {
        font-size: 12px;
        line-height: 20px;
        color: $med-gray;
        text-align: center;
        margin-top: 34px;
    }
    button.btn.btn-primary {
        margin-top: 24px;
    }
    .password-wrapper {
        margin-top: 24px;
    }
    .password-validation {
        @include text-small;
        @include bold-md;
        margin-top: 16px;
        ul {
            font-weight: normal;
            list-style: none;
            padding: 0;
            margin-top: 8px;
            li {
                display: flex;
                margin-bottom: 8px;
                align-items: center;
            }
        }
    }
}