@import '~styles/colors';
@import '~styles/typography';

.valid-checkbox {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    background: $subtitle-1;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.valid {
        background: $success;
    }
}