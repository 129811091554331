@import '~styles/colors';
@import '~styles/typography';

#edit-car-scene {
    #nav-header {
        margin-bottom: 22px;
    }
    h2 {
        margin-top: 8px;
        margin-bottom: 24px;
    }
    .car-details {
        display: flex;
        align-items: flex-start;
        max-width: 500px;
        margin-bottom: 24px;

        #car-img {
            width: 30%;
            margin-right: 22px;
        }
        #make-model {
            flex: 1;
            .make {
                color: $med-gray;
                @include bold-md;
                margin-bottom: 8px;
            }
        }
        #logo-img {
            width: 24px;
        }
    }
    form {
        button.btn.btn-link {
            margin-top: 48px;
            margin-bottom: 10px;
        }
        button {
            margin-top: 24px;
        }
    }
}