@import '~styles/colors';
@import '~styles/inputs';
@import '~styles/typography';

.icon-input {
    border-radius: 10px;
    border: 2px solid transparent;
    width: 100%;
    &.invalid {
        border-color: $error;
        background: linear-gradient(0deg, rgba(219, 52, 52, 0.1), rgba(219, 52, 52, 0.1)), #FFFFFF;
        .input-group, input:not(:focus) {
            background-color: #fbeaea;
        }
    }
    &.active {
        border-color:var(--branding-color-primary);
        background: $white;
        box-shadow: 0px 6px 12px rgba(9, 54, 86, 0.16);
        .input-group {
            background: inherit;
        }
    }
    .input-group {
        display: flex;
        align-items: center;
        @include input;
        padding: 0 20px;
        input {
            flex: 2;
            position: relative;
            padding: 0;
            left: 20px;
            padding: 12px 0;
            &.append-icon {
                left: 0;
            }
            &:focus {
                border-color: transparent !important;
                box-shadow: none !important;
            }
        }
        svg {
            padding: 12px 0;
        }
    }
}
