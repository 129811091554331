@import '~styles/typography';
@import '~styles/colors';

#nav-header {
    display: flex;
    width: 100%;
    margin-top: 18px;
    justify-content: space-between;
    align-items: center;
    .contact-wrapper {
        display: flex;
        cursor: pointer;
        align-items: center;
        .name {
            font-family: 'Roboto Condensed';
            margin-right: 28px;
            @include btn-text-small;
        }
    }
}