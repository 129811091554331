h1,h2,h3,h4,h5,h6 {
    margin: 0;
}
h1 {
    font-size: 59px;
    line-height: 64px;
    font-weight: 200;
}
h2 {
    font-size: 28px;
    line-height: 40px;
    font-weight: bold;
}
h3 {
    font-family: 'Roboto Condensed';
    font-size: 21px;
    line-height: 32px;
    font-weight: bold;
    letter-spacing: 2px;
    text-transform: uppercase;
}
h4 {
    font-size: 21px;
    line-height: 32px;
}
h5 {
    font-family: 'Roboto Condensed';
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}
h6 {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 16px;
}

@mixin btn-text-lg {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 2px;
    text-transform: uppercase;
    font-family: 'Roboto Condensed';
}

@mixin btn-text-small {
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

@mixin bold {
    font-weight: bold;
}

@mixin bold-md {
    font-weight: 500;
}

@mixin text-lg {
    font-size: 19px;
    line-height: 32px;
}

@mixin text-md {
    font-size: 16px;
    line-height: 24px;
}

@mixin text-small {
    font-size: 14px;
    line-height: 20px;
}

@mixin text-tiny {
    font-size: 12px;
    line-height: 20px;
}