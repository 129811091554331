@import '~styles/typography';
@import '~styles/colors';

#account-scene {
    #nav-header {
        margin-bottom: 32px;
    }
    h2 {
        margin-bottom: 16px;
    }
    .sub-text {
        @include text-small;
        color: $med-gray;
    }
    .section-title {
        align-self: flex-start;
        margin-bottom: 16px;
        @include text-lg;
        @include bold;
    }
    section, .car {
        border: 1px solid $divider;
        border-radius: 10px;
        margin-bottom: 48px;
        padding: 24px;
        width: 100%;
        max-width: 500px;
        .actions {
            position: relative;
            top: -7px;
            a:first-child {
                margin-right: 16px;
            }
        }
        button.btn.btn-action {
            margin-top: 24px;
        }
        .space-between {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
        }
        .car-imgs {
            margin-bottom: 16px;
        }
        .cc-details {
            display: flex;
            svg {
                margin-right: 16px;
            }
        }
        h6 {
            margin-bottom: 0px;
        }
        .value:not(:last-child) {
            margin-bottom: 16px;
        }
        .lg-value {
            @include bold;
        }
    }
    .car:not(:last-of-type) {
        margin-bottom: 16px;
    }
    button.btn-danger {
        margin-top: 24px;
    }
    .car-link {
        margin-top: 8px;
        margin-bottom: 48px;
    }
    .badge {
        border-radius: 50px;
        background-color: $dark-gray;
        color: $white;
        display: inline-block;
        padding: 8px 16px;
        margin-top: 32px;

        &.badge-blue {
            color:var(--branding-color-primary);
            background-color: #ebf5fb;
        }
    }
}