@import '~styles/colors';

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: $dark-gray;
  font-weight: normal;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $dark-gray;
  font-weight: normal;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: $dark-gray;
  font-weight: normal;
}

@mixin input {
    background-color: $subtitle-2;
    border-radius: 10px;
    font-size: 19px;
    line-height: 32px;
    color: $dark-gray;
    outline: none;
    border: 1.5px solid transparent;
    padding: 12px 20px;
    font-weight: bold;
    box-sizing:border-box;
    &:focus {
      background: $white;
    }
}

input {
    @include input;
    width: 100%;
    max-width: 500px;
    &:focus {
      border: 1.5px solidvar(--branding-color-primary);
      box-shadow: 0px 6px 12px rgba(9, 54, 86, 0.16);
    }
    &.invalid {
      background: #fbebeb;
      border: 1.5px solid $error;
    }
}