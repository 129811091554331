
// Blue
$teq-blue: var(--branding-color-primary);
$teq-blue-dark: #2C80B8;

// Black - Gray - White
$black: #0A1E2C;
$dark-gray: #3B4B56;;
$med-gray: #6C7880;
$lit-gray: #9DA5AB;
$white: #FFFFFF;
$divider: #CED2D5;
$subtitle-1: #E7E9EA;
$subtitle-2: #F0F2F2;
$subtitle-3: #F5F6F7;
$subtitle-4: #FAFBFB;

// Red
$error: #DB3434;


// Green
$success: #14C83C;

// Yellow
$warning: #FFD70F;

.danger {
    color: $error;
}