form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 500px;
    > .dropdown {
        margin-bottom: 24px;
    }
    h6 {
        &:not(:first-of-type) {
            margin-top: 24px;
        }
        margin-bottom: 8px;
    }
}