@import '~styles/colors';
@import '~styles/typography';
@import '~styles/inputs';

#charger-picker-scene {
    #nav-header {
        margin-bottom: 32px;
    }
    h2, .sub-header {
        align-self: flex-start;
    }
    .sub-header {
        color: $med-gray;
        margin-bottom: 24px;
    }
    .selection-wrapper, .searching-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .or {
        @include bold;
        margin: 16px 0;
    }
    button.btn.btn-input {
        @include input;
        font-family: Roboto;
        letter-spacing: 1px;
        font-weight: normal;
        text-transform: none;
        outline: none;
        &:focus {
          background: $subtitle-2;
        }
    }
    button.btn.btn-qr {
        @include btn-text-lg;
        display: flex;
        color: $black;
        justify-content: center;
        align-items: center;
        background-color: $white;
        border: 1px solid $divider;
        svg {
            margin-right: 16px;
        }
    }
    .searching-wrapper {
        > .icon-input {
           margin-bottom: 30px;
        }
        > section {
            margin-bottom: 24px;
        }
        > .loader-wrapper {
            display: flex;
            align-items: center;
            @include text-small;
            @include bold-md;
            color: $med-gray;
            align-self: flex-start;
            margin-bottom: 30px;
            .loader {
                margin-right: 9px;
            }
        }
        .result {
            &:first-of-type {
                margin-top: 10px;
            }
            color: $black;
            cursor: pointer;
            margin-bottom: 20px;
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            > svg {
                margin-right: 10px;
            }
            .details-wrapper {
                display: flex;
                align-items: center;
                svg {
                    margin-right: 19px;
                }
                .details {
                    .name {
                        @include btn-text-lg;
                    }
                    .address {
                        @include text-tiny;
                        @include bold-md;
                        color: $med-gray;
                    }
                }
            }
        }
    }
}