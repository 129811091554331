@import '~styles/colors';
@import '~styles/inputs';

form.cc-form {
    .cc-input-wrapper {
        @include input;
        padding: 18px 20px;
        &.focused {
            border: 1.5px solidvar(--branding-color-primary);
            background: $white;
            box-shadow: 0px 6px 12px rgba(9, 54, 86, 0.16);
        }
    }
    .icon-input-wrapper {
            margin-bottom: 24px;
        }
        .field-group {
            display: flex;
            .form-input-group {
                flex: 1;
                &:not(:last-child) {
                    margin-right: 10px;
                }
            }
        }
    .cancel {
        margin-bottom: 34px;
    }
}