#car-picker-scene {
    h2 {
        margin-top: 48px;
        margin-bottom: 16px;
        align-self: flex-start;
    }
    > .search-input {
        align-self: flex-start;
        margin-bottom: 40px;
    }
    h6 {
        align-self: flex-start;
    }
    section {
        width: 100%;
        margin-bottom: 32px;
        &:last-of-type {
            margin-bottom: 68px;
        }
    }
    .car-options {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        .car-option {
            margin-right: 8px;
            margin-bottom: 8px;
            width: calc(50% - 8px);
        }
    }
    button.btn-primary {
        position: fixed;
        bottom: 0;
        left: 0;
        max-width: 100%;
        margin: 0 20px;
        margin-bottom: 16px !important;
        width: calc(100% - 48px);
    }
}