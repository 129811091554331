@import '~styles/colors';
@import '~styles/typography';

.car-option {
    background: $white;
    border: 1px solid $divider;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;
    position: relative;

    .check-mark {
        display: flex;
        border-radius: 48px;
        width: 48px;
        height: 48px;
        background:var(--branding-color-primary);
        position: absolute;
        left: 0;
        right: 0;
        top: 33%;
        margin-left: auto;
        margin-right: auto;
        justify-content: center;
        align-items: center;
    }

    &.not-selected {
        opacity: 0.6;
    }

    &.selected {
        box-sizing: border-box;
        border-radius: 10px;
        border: 1.5px solidvar(--branding-color-primary);
        box-shadow: 0px 6px 12px rgba(9, 54, 86, 0.16);
    }

    .details {
        @include text-tiny;
        @include bold-md;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    img.car-img {
        display: block;
        margin: 0 auto;
        width: 100%;
    }
}