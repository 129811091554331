@import '~styles/typography';
@import '~styles/colors';

.form-input-group {
    width: 100%;
    display: block;
    .error {
        margin-top: 8px;
        @include text-small;
        color: $error;
    }
    input {
        width: 100%;
    }
}