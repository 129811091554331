@import '~styles/typography';
@import '~styles/colors';

#dashboard-scene {
    #nav-header {
        margin-bottom: 52px;
    }
    h2 {
        align-self: center;
    }
    .car-logo {
        width: 44px;
        margin-bottom: 16px;
    }
    .car-details {
        @include text-small;
        color: $med-gray;
    }
    a.edit {
        @include text-md;
        @include bold-md;
        margin-bottom: 16px;
    }
    img.car {
        margin-bottom: 20px;
    }
    button.mega {
        margin-bottom: 40px;
    }
    @media(min-height: 500px) {
        button.btn-mega {
            position: fixed;
            bottom: 0;
            left: 0;
            max-width: 100%;
            margin: 0 20px;
            margin-bottom: 16px !important;
            width: calc(100% - 48px);
        }
    }
    .img-container {
        display: flex;
        position: relative;
        width: 400px;
        align-items: center;
        flex-direction: column;
        .overlay {
            h6 {
                position: absolute;
                top: 22px;
            }
            h1 {
                margin-bottom: 16px;
                width: 180px;
                display: inline-block;
                position: absolute;
                left: 25%;
                top: 55px;
                text-align: right;
            }
            button.btn.btn-danger {
                position: absolute;
                bottom: 22px;
                max-width: 160px;
                @include btn-text-small;
            }
        }
    }
    .location {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        svg {
            margin-bottom: 12px;
        }
        .name {
            @include btn-text-lg;
        }
        .btn-link {
            margin-top: 8px;
            font-family: Roboto;
            @include text-tiny;
            letter-spacing: 0px;
            text-transform: none;
            color:var(--branding-color-primary);
        }
        .address {
            @include text-tiny;
            @include bold-md;
            color: $med-gray;
        }
    }
}