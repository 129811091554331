@import '~styles/colors';
@import '~styles/inputs';
@import '~styles/typography';

.icon-input-wrapper {
    .error {
        margin-top: 8px;
        @include text-small;
        color: $error;
    }
}
.icon-input {
    border-radius: 10px;
    border: 2px solid transparent;
    width: 100%;
    &.invalid {
        border-color: $error;
        background: #fbebeb;
        .input-group, input:not(:focus) {
            background: #fbebeb;
        }
    }
    &.active {
        border-color:var(--branding-color-primary);
        background: $white;
        .input-group {
            background: inherit;
        }
    }
    .input-group {
        display: flex;
        align-items: center;
        @include input;
        padding: 0 20px;
        input:invalid {
            box-shadow: none;
        }
        input {
            outline: none;
            flex: 2;
            position: relative;
            padding: 0;
            left: 20px;
            padding: 12px 0;
            &.append-icon {
                left: 0;
            }
            &:focus {
                border-color: transparent !important;
                box-shadow: none !important;
            }
        }
        svg {
            padding: 12px 0;
        }
    }
}
